.App {
    text-align: center;
    min-width: 100%;
    width: fit-content;
    min-height: 100vh !important;
    position: relative;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.side-menu {
    min-height: 100vh;
}

label {
    font-weight: 500;
}

.prefix-select {
    min-width: 150px;
}

.prefix-select .ant-select-arrow {
    left: 11px;
    right: unset;
}

.prefix-select .ant-select-selection-item,
.prefix-select .ant-select-selection-placeholder {
    margin-left: 20px;
}

.prefix-select .ant-select-selection-search {
    margin-left: 20px;
}

.ant-form-item-required::before {
    display: none !important;
}

.ant-form-item-required::after {
    display: inline-block;
    margin-right: 4px;
    color: #f5222d;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
}

.guild-item .ant-descriptions-item-label,
.guild-item .ant-descriptions-item-content {
    font-size: 1.1em;
}

.guild-item .ant-descriptions-item-label {
    font-weight: 500;
}

.loader {
    width: 100%;
    text-align: center;
}

.emoji {
    display: inline-block;
    width: auto;
    height: 1em;
    vertical-align: -0.125em;
}

.react-emoji .emoji,
.select-label .emoji {
    font-size: 2em;
}

.select-label .emoji {
    vertical-align: -.2em;
    height: .8em;
}

td .ant-input-number, td .ant-select {
    min-width: unset;
    width: 100%;
}

.ant-btn .ant-avatar {
    float: unset !important;
}

.profile-dropdown.ant-btn {
    height: fit-content;
    padding: .4em;
}

ins.adsbygoogle {
    width: 100%;
    height: 100%;
}

.sidebar-ad {
    width: 100%;
    height: 150px;
}

.banner-ad {
    width: 100%;
    height: 80px;
    margin-bottom: 20px;
}

.premium-page {
    width: 100%;
}

.premium-card {
    display: inline-block;
    width: 300px;
    margin-left: auto;
    margin-right: auto;
}

.payment-modal, .payment-modal .ant-modal-footer {
    text-align: center !important;
}

.payment-modal .ant-modal-body {
    text-align: left;
}

.payment-modal {
    min-width: 700px;
}

.guild-item-modal, .ticket-type-modal {
    min-width: 700px;
    max-width: 100vw;
}

.ant-input.StripeElement {
    padding: 10px;
}

.card-form {
    width: 100%;
}

.card-icon {
    font-size: 2em;
    margin-right: 10px;
    vertical-align: -.1em !important;
}

.ant-form-item .ant-alert {
    margin-top: 10px;
}

.subscriptions .ant-card-body {
    max-height: 60vh;
    overflow: auto;
}

.subscriptions .ant-card {
    text-align: left;
}

.profile {
    margin-left: 20%;
    margin-right: 20%;
}

@media screen and (max-width: 768px) {
    .profile {
        margin-left: 0;
        margin-right: 0;
    }
}

.profile .ant-form {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
}

.logo {
    max-width: 80%;
    margin-left: -8px;
}

.role {
    white-space: nowrap;
    border: 1px solid;
    border-radius: 20px;
    padding: 2px 8px 2px 5px;
}

.role .anticon {
    margin-right: 5px;
}

.help-icon {
    margin-left: 10px;
}

.message-preview {
    background-color: #2F3136;
    padding: .75rem 1rem 1rem .75rem;
    border-left: 4px #8BD227 solid;
    border-radius: 4px;
    max-width: 520px;
    width: fit-content;
}

.message-preview.bday {
    border-color: #A531EF;
}

.message-preview.welcome {
    border-color: #7ED321;
}

code {
    background-color: #202225;
    padding: .2em;
    border-radius: 3px;
    font-size: 85% !important;
}

pre {
    white-space: normal;
    background-color: #202225;
    border-radius: 4px;
    padding: .5em;
    margin: 6px 0 0 0 !important;
}

pre code {
    font-size: 100% !important;
    white-space: pre-wrap;
}

.d-mention {
    color: #7289da;
    background: rgba(114, 137, 218, .1);
    transition: background-color 50ms ease-out, color 50ms ease-out !important;
    cursor: pointer;
    border-radius: 3px;
    padding: 0 2px;
}

.d-mention:hover {
    background-color: #7289da;
    color: #fff;
}

/*Metronic menu stuff*/
.kt-aside__brand {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    padding: 0 25px;
    height: 65px;
}

.kt-aside__brand .kt-aside__brand-tools .kt-aside__brand-aside-toggler {
    display: inline-block;
    padding: 0;
    border: 0;
    background: none;
    outline: none !important;
    -webkit-box-shadow: none;
    box-shadow: none;
    cursor: pointer;
}

.kt-aside__brand .kt-aside__brand-tools .kt-aside__brand-aside-toggler span {
    display: inline-block;
}

.kt-aside__brand .kt-aside__brand-tools .kt-aside__brand-aside-toggler span:nth-child(2) {
    display: none;
}

.kt-aside__brand .kt-aside__brand-tools .kt-aside__brand-aside-toggler svg {
    height: 26px;
    width: 26px;
    margin-right: -2px;
}
